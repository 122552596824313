import { default as bankpIbsVDW2tuMeta } from "/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/account/bank.vue?macro=true";
import { default as bankErq6QAcUqKMeta } from "/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/account/deposit/bank.vue?macro=true";
import { default as cardY83Rmjxsw1Meta } from "/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/account/deposit/card.vue?macro=true";
import { default as codepay7NKRd3WaQPMeta } from "/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/account/deposit/codepay.vue?macro=true";
import { default as codepay2WzvM6Zo2lbMeta } from "/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/account/deposit/codepay2.vue?macro=true";
import { default as crypto6g2cYKk9pWMeta } from "/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/account/deposit/crypto.vue?macro=true";
import { default as ewallet9mAPDl1H2zMeta } from "/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/account/deposit/ewallet.vue?macro=true";
import { default as betq7BLQOJDa9Meta } from "/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/account/history/bet.vue?macro=true";
import { default as transaction8ZbjQjJhfoMeta } from "/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/account/history/transaction.vue?macro=true";
import { default as historyK8U9WjRiA5Meta } from "/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/account/history.vue?macro=true";
import { default as profileFRvP5xD2wjMeta } from "/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/account/profile.vue?macro=true";
import { default as profit_45todayYeXyxSa1kCMeta } from "/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/account/profit-today.vue?macro=true";
import { default as indexUjuEw293esMeta } from "/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/account/referral/index.vue?macro=true";
import { default as trading_45p2pTGJFcmUeeuMeta } from "/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/account/trading-p2p.vue?macro=true";
import { default as bank9d06dCK9LKMeta } from "/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/account/withdraw/bank.vue?macro=true";
import { default as cardAdPJOJDaY9Meta } from "/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/account/withdraw/card.vue?macro=true";
import { default as crypto50GCpAfW1tMeta } from "/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/account/withdraw/crypto.vue?macro=true";
import { default as accountjdpRQQ0F9MMeta } from "/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/account.vue?macro=true";
import { default as base_45buttonCFLy4mVmRQMeta } from "/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/dev/base-button.vue?macro=true";
import { default as base_45count_45down9UBJHnFKZYMeta } from "/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/dev/base-count-down.vue?macro=true";
import { default as base_45dropdown_45radiog06wJlj9Q2Meta } from "/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/dev/base-dropdown-radio.vue?macro=true";
import { default as base_45imgpypVYI1P9SMeta } from "/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/dev/base-img.vue?macro=true";
import { default as base_45inputXNsLpjlze9Meta } from "/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/dev/base-input.vue?macro=true";
import { default as base_45loadingEIRPhL3rKnMeta } from "/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/dev/base-loading.vue?macro=true";
import { default as base_45modalfNmcF5Vr4IMeta } from "/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/dev/base-modal.vue?macro=true";
import { default as base_45money_45inputrcOWSQc8C3Meta } from "/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/dev/base-money-input.vue?macro=true";
import { default as base_45paginationlvNYZKDy5LMeta } from "/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/dev/base-pagination.vue?macro=true";
import { default as base_45select_45bankTqKdjI1KCIMeta } from "/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/dev/base-select-bank.vue?macro=true";
import { default as game_45item326rR3yQ4DMeta } from "/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/dev/game-item.vue?macro=true";
import { default as indexomb2MmCsXeMeta } from "/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/dev/index.vue?macro=true";
import { default as _91type_939DMBfhG2lSMeta } from "/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/game/[type].vue?macro=true";
import { default as gamekrAOd25u5zMeta } from "/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/game.vue?macro=true";
import { default as _91alias_93uBhOHJxunZMeta } from "/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/gioi-thieu/[alias].vue?macro=true";
import { default as gioi_45thieu5VNqS8O8BOMeta } from "/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/gioi-thieu.vue?macro=true";
import { default as _91alias_93hK2h0kpPnBMeta } from "/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/huong-dan/[alias].vue?macro=true";
import { default as huong_45danlC3RNyzkaAMeta } from "/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/huong-dan.vue?macro=true";
import { default as indexzxYGi4Op5AMeta } from "/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/index.vue?macro=true";
import { default as _91id_93Zf7ALMZVSBMeta } from "/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/khuyen-mai/[id].vue?macro=true";
import { default as indexxiLmoKTAKIMeta } from "/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/khuyen-mai/index.vue?macro=true";
import { default as _91id_933ZeijTYMRqMeta } from "/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/khuyen-mai/the-loai/[id].vue?macro=true";
import { default as khuyen_45mai0wcEmvWILZMeta } from "/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/khuyen-mai.vue?macro=true";
import { default as _91type_93FYW3MYS83hMeta } from "/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/livecasino/[type].vue?macro=true";
import { default as livecasinoKocR6juQeYMeta } from "/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/livecasino.vue?macro=true";
import { default as reset_45passwordKEWy1clWudMeta } from "/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/reset-password.vue?macro=true";
import { default as searchgVqamTjMaAMeta } from "/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/search.vue?macro=true";
import { default as _91id_93XeK2CXgABvMeta } from "/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/the-thao/[id].vue?macro=true";
import { default as indexmAD9ATmlLZMeta } from "/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/the-thao/index.vue?macro=true";
import { default as indexIyQHZ64fIvMeta } from "/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/the-thao/lich-thi-dau/index.vue?macro=true";
import { default as _91alias_93FQdfgnFqDOMeta } from "/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/tin-tuc/[alias].vue?macro=true";
import { default as index4YpBscPw3NMeta } from "/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/tin-tuc/index.vue?macro=true";
import { default as _91Slug_93YN9W7i0yqTMeta } from "/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/tin-tuc/the-loai/[Slug].vue?macro=true";
import { default as tin_45tucCaTuFXMjsKMeta } from "/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/tin-tuc.vue?macro=true";
import { default as verify_45mailo8TE42XQhPMeta } from "/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/verify-mail.vue?macro=true";
export default [
  {
    name: accountjdpRQQ0F9MMeta?.name ?? "account",
    path: accountjdpRQQ0F9MMeta?.path ?? "/account",
    children: [
  {
    name: bankpIbsVDW2tuMeta?.name ?? "account-bank",
    path: bankpIbsVDW2tuMeta?.path ?? "bank",
    meta: bankpIbsVDW2tuMeta || {},
    alias: bankpIbsVDW2tuMeta?.alias || [],
    redirect: bankpIbsVDW2tuMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/account/bank.vue").then(m => m.default || m)
  },
  {
    name: bankErq6QAcUqKMeta?.name ?? "account-deposit-bank",
    path: bankErq6QAcUqKMeta?.path ?? "deposit/bank",
    meta: bankErq6QAcUqKMeta || {},
    alias: bankErq6QAcUqKMeta?.alias || [],
    redirect: bankErq6QAcUqKMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/account/deposit/bank.vue").then(m => m.default || m)
  },
  {
    name: cardY83Rmjxsw1Meta?.name ?? "account-deposit-card",
    path: cardY83Rmjxsw1Meta?.path ?? "deposit/card",
    meta: cardY83Rmjxsw1Meta || {},
    alias: cardY83Rmjxsw1Meta?.alias || [],
    redirect: cardY83Rmjxsw1Meta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/account/deposit/card.vue").then(m => m.default || m)
  },
  {
    name: codepay7NKRd3WaQPMeta?.name ?? "account-deposit-codepay",
    path: codepay7NKRd3WaQPMeta?.path ?? "deposit/codepay",
    meta: codepay7NKRd3WaQPMeta || {},
    alias: codepay7NKRd3WaQPMeta?.alias || [],
    redirect: codepay7NKRd3WaQPMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/account/deposit/codepay.vue").then(m => m.default || m)
  },
  {
    name: codepay2WzvM6Zo2lbMeta?.name ?? "account-deposit-codepay2",
    path: codepay2WzvM6Zo2lbMeta?.path ?? "deposit/codepay2",
    meta: codepay2WzvM6Zo2lbMeta || {},
    alias: codepay2WzvM6Zo2lbMeta?.alias || [],
    redirect: codepay2WzvM6Zo2lbMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/account/deposit/codepay2.vue").then(m => m.default || m)
  },
  {
    name: crypto6g2cYKk9pWMeta?.name ?? "account-deposit-crypto",
    path: crypto6g2cYKk9pWMeta?.path ?? "deposit/crypto",
    meta: crypto6g2cYKk9pWMeta || {},
    alias: crypto6g2cYKk9pWMeta?.alias || [],
    redirect: crypto6g2cYKk9pWMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/account/deposit/crypto.vue").then(m => m.default || m)
  },
  {
    name: ewallet9mAPDl1H2zMeta?.name ?? "account-deposit-ewallet",
    path: ewallet9mAPDl1H2zMeta?.path ?? "deposit/ewallet",
    meta: ewallet9mAPDl1H2zMeta || {},
    alias: ewallet9mAPDl1H2zMeta?.alias || [],
    redirect: ewallet9mAPDl1H2zMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/account/deposit/ewallet.vue").then(m => m.default || m)
  },
  {
    name: historyK8U9WjRiA5Meta?.name ?? "account-history",
    path: historyK8U9WjRiA5Meta?.path ?? "history",
    children: [
  {
    name: betq7BLQOJDa9Meta?.name ?? "account-history-bet",
    path: betq7BLQOJDa9Meta?.path ?? "bet",
    meta: betq7BLQOJDa9Meta || {},
    alias: betq7BLQOJDa9Meta?.alias || [],
    redirect: betq7BLQOJDa9Meta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/account/history/bet.vue").then(m => m.default || m)
  },
  {
    name: transaction8ZbjQjJhfoMeta?.name ?? "account-history-transaction",
    path: transaction8ZbjQjJhfoMeta?.path ?? "transaction",
    meta: transaction8ZbjQjJhfoMeta || {},
    alias: transaction8ZbjQjJhfoMeta?.alias || [],
    redirect: transaction8ZbjQjJhfoMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/account/history/transaction.vue").then(m => m.default || m)
  }
],
    meta: historyK8U9WjRiA5Meta || {},
    alias: historyK8U9WjRiA5Meta?.alias || [],
    redirect: historyK8U9WjRiA5Meta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/account/history.vue").then(m => m.default || m)
  },
  {
    name: profileFRvP5xD2wjMeta?.name ?? "account-profile",
    path: profileFRvP5xD2wjMeta?.path ?? "profile",
    meta: profileFRvP5xD2wjMeta || {},
    alias: profileFRvP5xD2wjMeta?.alias || [],
    redirect: profileFRvP5xD2wjMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: profit_45todayYeXyxSa1kCMeta?.name ?? "account-profit-today",
    path: profit_45todayYeXyxSa1kCMeta?.path ?? "profit-today",
    meta: profit_45todayYeXyxSa1kCMeta || {},
    alias: profit_45todayYeXyxSa1kCMeta?.alias || [],
    redirect: profit_45todayYeXyxSa1kCMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/account/profit-today.vue").then(m => m.default || m)
  },
  {
    name: indexUjuEw293esMeta?.name ?? "account-referral",
    path: indexUjuEw293esMeta?.path ?? "referral",
    meta: indexUjuEw293esMeta || {},
    alias: indexUjuEw293esMeta?.alias || [],
    redirect: indexUjuEw293esMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/account/referral/index.vue").then(m => m.default || m)
  },
  {
    name: trading_45p2pTGJFcmUeeuMeta?.name ?? "account-trading-p2p",
    path: trading_45p2pTGJFcmUeeuMeta?.path ?? "trading-p2p",
    meta: trading_45p2pTGJFcmUeeuMeta || {},
    alias: trading_45p2pTGJFcmUeeuMeta?.alias || [],
    redirect: trading_45p2pTGJFcmUeeuMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/account/trading-p2p.vue").then(m => m.default || m)
  },
  {
    name: bank9d06dCK9LKMeta?.name ?? "account-withdraw-bank",
    path: bank9d06dCK9LKMeta?.path ?? "withdraw/bank",
    meta: bank9d06dCK9LKMeta || {},
    alias: bank9d06dCK9LKMeta?.alias || [],
    redirect: bank9d06dCK9LKMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/account/withdraw/bank.vue").then(m => m.default || m)
  },
  {
    name: cardAdPJOJDaY9Meta?.name ?? "account-withdraw-card",
    path: cardAdPJOJDaY9Meta?.path ?? "withdraw/card",
    meta: cardAdPJOJDaY9Meta || {},
    alias: cardAdPJOJDaY9Meta?.alias || [],
    redirect: cardAdPJOJDaY9Meta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/account/withdraw/card.vue").then(m => m.default || m)
  },
  {
    name: crypto50GCpAfW1tMeta?.name ?? "account-withdraw-crypto",
    path: crypto50GCpAfW1tMeta?.path ?? "withdraw/crypto",
    meta: crypto50GCpAfW1tMeta || {},
    alias: crypto50GCpAfW1tMeta?.alias || [],
    redirect: crypto50GCpAfW1tMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/account/withdraw/crypto.vue").then(m => m.default || m)
  }
],
    meta: accountjdpRQQ0F9MMeta || {},
    alias: accountjdpRQQ0F9MMeta?.alias || [],
    redirect: accountjdpRQQ0F9MMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/account.vue").then(m => m.default || m)
  },
  {
    name: base_45buttonCFLy4mVmRQMeta?.name ?? "dev-base-button",
    path: base_45buttonCFLy4mVmRQMeta?.path ?? "/dev/base-button",
    meta: base_45buttonCFLy4mVmRQMeta || {},
    alias: base_45buttonCFLy4mVmRQMeta?.alias || [],
    redirect: base_45buttonCFLy4mVmRQMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/dev/base-button.vue").then(m => m.default || m)
  },
  {
    name: base_45count_45down9UBJHnFKZYMeta?.name ?? "dev-base-count-down",
    path: base_45count_45down9UBJHnFKZYMeta?.path ?? "/dev/base-count-down",
    meta: base_45count_45down9UBJHnFKZYMeta || {},
    alias: base_45count_45down9UBJHnFKZYMeta?.alias || [],
    redirect: base_45count_45down9UBJHnFKZYMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/dev/base-count-down.vue").then(m => m.default || m)
  },
  {
    name: base_45dropdown_45radiog06wJlj9Q2Meta?.name ?? "dev-base-dropdown-radio",
    path: base_45dropdown_45radiog06wJlj9Q2Meta?.path ?? "/dev/base-dropdown-radio",
    meta: base_45dropdown_45radiog06wJlj9Q2Meta || {},
    alias: base_45dropdown_45radiog06wJlj9Q2Meta?.alias || [],
    redirect: base_45dropdown_45radiog06wJlj9Q2Meta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/dev/base-dropdown-radio.vue").then(m => m.default || m)
  },
  {
    name: base_45imgpypVYI1P9SMeta?.name ?? "dev-base-img",
    path: base_45imgpypVYI1P9SMeta?.path ?? "/dev/base-img",
    meta: base_45imgpypVYI1P9SMeta || {},
    alias: base_45imgpypVYI1P9SMeta?.alias || [],
    redirect: base_45imgpypVYI1P9SMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/dev/base-img.vue").then(m => m.default || m)
  },
  {
    name: base_45inputXNsLpjlze9Meta?.name ?? "dev-base-input",
    path: base_45inputXNsLpjlze9Meta?.path ?? "/dev/base-input",
    meta: base_45inputXNsLpjlze9Meta || {},
    alias: base_45inputXNsLpjlze9Meta?.alias || [],
    redirect: base_45inputXNsLpjlze9Meta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/dev/base-input.vue").then(m => m.default || m)
  },
  {
    name: base_45loadingEIRPhL3rKnMeta?.name ?? "dev-base-loading",
    path: base_45loadingEIRPhL3rKnMeta?.path ?? "/dev/base-loading",
    meta: base_45loadingEIRPhL3rKnMeta || {},
    alias: base_45loadingEIRPhL3rKnMeta?.alias || [],
    redirect: base_45loadingEIRPhL3rKnMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/dev/base-loading.vue").then(m => m.default || m)
  },
  {
    name: base_45modalfNmcF5Vr4IMeta?.name ?? "dev-base-modal",
    path: base_45modalfNmcF5Vr4IMeta?.path ?? "/dev/base-modal",
    meta: base_45modalfNmcF5Vr4IMeta || {},
    alias: base_45modalfNmcF5Vr4IMeta?.alias || [],
    redirect: base_45modalfNmcF5Vr4IMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/dev/base-modal.vue").then(m => m.default || m)
  },
  {
    name: base_45money_45inputrcOWSQc8C3Meta?.name ?? "dev-base-money-input",
    path: base_45money_45inputrcOWSQc8C3Meta?.path ?? "/dev/base-money-input",
    meta: base_45money_45inputrcOWSQc8C3Meta || {},
    alias: base_45money_45inputrcOWSQc8C3Meta?.alias || [],
    redirect: base_45money_45inputrcOWSQc8C3Meta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/dev/base-money-input.vue").then(m => m.default || m)
  },
  {
    name: base_45paginationlvNYZKDy5LMeta?.name ?? "dev-base-pagination",
    path: base_45paginationlvNYZKDy5LMeta?.path ?? "/dev/base-pagination",
    meta: base_45paginationlvNYZKDy5LMeta || {},
    alias: base_45paginationlvNYZKDy5LMeta?.alias || [],
    redirect: base_45paginationlvNYZKDy5LMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/dev/base-pagination.vue").then(m => m.default || m)
  },
  {
    name: base_45select_45bankTqKdjI1KCIMeta?.name ?? "dev-base-select-bank",
    path: base_45select_45bankTqKdjI1KCIMeta?.path ?? "/dev/base-select-bank",
    meta: base_45select_45bankTqKdjI1KCIMeta || {},
    alias: base_45select_45bankTqKdjI1KCIMeta?.alias || [],
    redirect: base_45select_45bankTqKdjI1KCIMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/dev/base-select-bank.vue").then(m => m.default || m)
  },
  {
    name: game_45item326rR3yQ4DMeta?.name ?? "dev-game-item",
    path: game_45item326rR3yQ4DMeta?.path ?? "/dev/game-item",
    meta: game_45item326rR3yQ4DMeta || {},
    alias: game_45item326rR3yQ4DMeta?.alias || [],
    redirect: game_45item326rR3yQ4DMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/dev/game-item.vue").then(m => m.default || m)
  },
  {
    name: indexomb2MmCsXeMeta?.name ?? "dev",
    path: indexomb2MmCsXeMeta?.path ?? "/dev",
    meta: indexomb2MmCsXeMeta || {},
    alias: indexomb2MmCsXeMeta?.alias || [],
    redirect: indexomb2MmCsXeMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/dev/index.vue").then(m => m.default || m)
  },
  {
    name: gamekrAOd25u5zMeta?.name ?? "game",
    path: gamekrAOd25u5zMeta?.path ?? "/game",
    children: [
  {
    name: _91type_939DMBfhG2lSMeta?.name ?? "game-type",
    path: _91type_939DMBfhG2lSMeta?.path ?? ":type",
    meta: _91type_939DMBfhG2lSMeta || {},
    alias: _91type_939DMBfhG2lSMeta?.alias || [],
    redirect: _91type_939DMBfhG2lSMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/game/[type].vue").then(m => m.default || m)
  }
],
    meta: gamekrAOd25u5zMeta || {},
    alias: gamekrAOd25u5zMeta?.alias || [],
    redirect: gamekrAOd25u5zMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/game.vue").then(m => m.default || m)
  },
  {
    name: gioi_45thieu5VNqS8O8BOMeta?.name ?? "gioi-thieu",
    path: gioi_45thieu5VNqS8O8BOMeta?.path ?? "/gioi-thieu",
    children: [
  {
    name: _91alias_93uBhOHJxunZMeta?.name ?? "gioi-thieu-alias",
    path: _91alias_93uBhOHJxunZMeta?.path ?? ":alias",
    meta: _91alias_93uBhOHJxunZMeta || {},
    alias: _91alias_93uBhOHJxunZMeta?.alias || [],
    redirect: _91alias_93uBhOHJxunZMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/gioi-thieu/[alias].vue").then(m => m.default || m)
  }
],
    meta: gioi_45thieu5VNqS8O8BOMeta || {},
    alias: gioi_45thieu5VNqS8O8BOMeta?.alias || [],
    redirect: gioi_45thieu5VNqS8O8BOMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/gioi-thieu.vue").then(m => m.default || m)
  },
  {
    name: huong_45danlC3RNyzkaAMeta?.name ?? "huong-dan",
    path: huong_45danlC3RNyzkaAMeta?.path ?? "/huong-dan",
    children: [
  {
    name: _91alias_93hK2h0kpPnBMeta?.name ?? "huong-dan-alias",
    path: _91alias_93hK2h0kpPnBMeta?.path ?? ":alias",
    meta: _91alias_93hK2h0kpPnBMeta || {},
    alias: _91alias_93hK2h0kpPnBMeta?.alias || [],
    redirect: _91alias_93hK2h0kpPnBMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/huong-dan/[alias].vue").then(m => m.default || m)
  }
],
    meta: huong_45danlC3RNyzkaAMeta || {},
    alias: huong_45danlC3RNyzkaAMeta?.alias || [],
    redirect: huong_45danlC3RNyzkaAMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/huong-dan.vue").then(m => m.default || m)
  },
  {
    name: indexzxYGi4Op5AMeta?.name ?? "index",
    path: indexzxYGi4Op5AMeta?.path ?? "/",
    meta: indexzxYGi4Op5AMeta || {},
    alias: indexzxYGi4Op5AMeta?.alias || [],
    redirect: indexzxYGi4Op5AMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/index.vue").then(m => m.default || m)
  },
  {
    path: khuyen_45mai0wcEmvWILZMeta?.path ?? "/khuyen-mai",
    children: [
  {
    name: _91id_93Zf7ALMZVSBMeta?.name ?? "khuyen-mai-id",
    path: _91id_93Zf7ALMZVSBMeta?.path ?? ":id",
    meta: _91id_93Zf7ALMZVSBMeta || {},
    alias: _91id_93Zf7ALMZVSBMeta?.alias || [],
    redirect: _91id_93Zf7ALMZVSBMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/khuyen-mai/[id].vue").then(m => m.default || m)
  },
  {
    name: indexxiLmoKTAKIMeta?.name ?? "khuyen-mai",
    path: indexxiLmoKTAKIMeta?.path ?? "",
    meta: indexxiLmoKTAKIMeta || {},
    alias: indexxiLmoKTAKIMeta?.alias || [],
    redirect: indexxiLmoKTAKIMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/khuyen-mai/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_933ZeijTYMRqMeta?.name ?? "khuyen-mai-the-loai-id",
    path: _91id_933ZeijTYMRqMeta?.path ?? "the-loai/:id",
    meta: _91id_933ZeijTYMRqMeta || {},
    alias: _91id_933ZeijTYMRqMeta?.alias || [],
    redirect: _91id_933ZeijTYMRqMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/khuyen-mai/the-loai/[id].vue").then(m => m.default || m)
  }
],
    name: khuyen_45mai0wcEmvWILZMeta?.name ?? undefined,
    meta: khuyen_45mai0wcEmvWILZMeta || {},
    alias: khuyen_45mai0wcEmvWILZMeta?.alias || [],
    redirect: khuyen_45mai0wcEmvWILZMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/khuyen-mai.vue").then(m => m.default || m)
  },
  {
    name: livecasinoKocR6juQeYMeta?.name ?? "livecasino",
    path: livecasinoKocR6juQeYMeta?.path ?? "/livecasino",
    children: [
  {
    name: _91type_93FYW3MYS83hMeta?.name ?? "livecasino-type",
    path: _91type_93FYW3MYS83hMeta?.path ?? ":type",
    meta: _91type_93FYW3MYS83hMeta || {},
    alias: _91type_93FYW3MYS83hMeta?.alias || [],
    redirect: _91type_93FYW3MYS83hMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/livecasino/[type].vue").then(m => m.default || m)
  }
],
    meta: livecasinoKocR6juQeYMeta || {},
    alias: livecasinoKocR6juQeYMeta?.alias || [],
    redirect: livecasinoKocR6juQeYMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/livecasino.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordKEWy1clWudMeta?.name ?? "reset-password",
    path: reset_45passwordKEWy1clWudMeta?.path ?? "/reset-password",
    meta: reset_45passwordKEWy1clWudMeta || {},
    alias: reset_45passwordKEWy1clWudMeta?.alias || [],
    redirect: reset_45passwordKEWy1clWudMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: searchgVqamTjMaAMeta?.name ?? "search",
    path: searchgVqamTjMaAMeta?.path ?? "/search",
    meta: searchgVqamTjMaAMeta || {},
    alias: searchgVqamTjMaAMeta?.alias || [],
    redirect: searchgVqamTjMaAMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/search.vue").then(m => m.default || m)
  },
  {
    name: _91id_93XeK2CXgABvMeta?.name ?? "the-thao-id",
    path: _91id_93XeK2CXgABvMeta?.path ?? "/the-thao/:id",
    meta: _91id_93XeK2CXgABvMeta || {},
    alias: _91id_93XeK2CXgABvMeta?.alias || [],
    redirect: _91id_93XeK2CXgABvMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/the-thao/[id].vue").then(m => m.default || m)
  },
  {
    name: indexmAD9ATmlLZMeta?.name ?? "the-thao",
    path: indexmAD9ATmlLZMeta?.path ?? "/the-thao",
    meta: indexmAD9ATmlLZMeta || {},
    alias: indexmAD9ATmlLZMeta?.alias || [],
    redirect: indexmAD9ATmlLZMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/the-thao/index.vue").then(m => m.default || m)
  },
  {
    name: indexIyQHZ64fIvMeta?.name ?? "the-thao-lich-thi-dau",
    path: indexIyQHZ64fIvMeta?.path ?? "/the-thao/lich-thi-dau",
    meta: indexIyQHZ64fIvMeta || {},
    alias: indexIyQHZ64fIvMeta?.alias || [],
    redirect: indexIyQHZ64fIvMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/the-thao/lich-thi-dau/index.vue").then(m => m.default || m)
  },
  {
    path: tin_45tucCaTuFXMjsKMeta?.path ?? "/tin-tuc",
    children: [
  {
    name: _91alias_93FQdfgnFqDOMeta?.name ?? "tin-tuc-alias",
    path: _91alias_93FQdfgnFqDOMeta?.path ?? ":alias",
    meta: _91alias_93FQdfgnFqDOMeta || {},
    alias: _91alias_93FQdfgnFqDOMeta?.alias || [],
    redirect: _91alias_93FQdfgnFqDOMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/tin-tuc/[alias].vue").then(m => m.default || m)
  },
  {
    name: index4YpBscPw3NMeta?.name ?? "tin-tuc",
    path: index4YpBscPw3NMeta?.path ?? "",
    meta: index4YpBscPw3NMeta || {},
    alias: index4YpBscPw3NMeta?.alias || [],
    redirect: index4YpBscPw3NMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/tin-tuc/index.vue").then(m => m.default || m)
  },
  {
    name: _91Slug_93YN9W7i0yqTMeta?.name ?? "tin-tuc-the-loai-Slug",
    path: _91Slug_93YN9W7i0yqTMeta?.path ?? "the-loai/:Slug",
    meta: _91Slug_93YN9W7i0yqTMeta || {},
    alias: _91Slug_93YN9W7i0yqTMeta?.alias || [],
    redirect: _91Slug_93YN9W7i0yqTMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/tin-tuc/the-loai/[Slug].vue").then(m => m.default || m)
  }
],
    name: tin_45tucCaTuFXMjsKMeta?.name ?? undefined,
    meta: tin_45tucCaTuFXMjsKMeta || {},
    alias: tin_45tucCaTuFXMjsKMeta?.alias || [],
    redirect: tin_45tucCaTuFXMjsKMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/tin-tuc.vue").then(m => m.default || m)
  },
  {
    name: verify_45mailo8TE42XQhPMeta?.name ?? "verify-mail",
    path: verify_45mailo8TE42XQhPMeta?.path ?? "/verify-mail",
    meta: verify_45mailo8TE42XQhPMeta || {},
    alias: verify_45mailo8TE42XQhPMeta?.alias || [],
    redirect: verify_45mailo8TE42XQhPMeta?.redirect || undefined,
    component: () => import("/Users/yoshi/Documents/Project/Brand/ps21mt-web/pages/verify-mail.vue").then(m => m.default || m)
  }
]